import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/umar/Projects/TechnaTools/bluetickpost-gatsby/src/components/BlogContent/BlogLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Markdown Syntax`}</h3>
    <p>{`You can write content using `}<a parentName="p" {...{
        "href": "https://github.github.com/gfm/"
      }}>{`GitHub-flavored Markdown syntax`}</a>{`.`}</p>
    <p>{`To serve as an example page when styling markdown based Docusaurus sites.`}</p>
    <h2>{`Headers`}</h2>
    <h1>{`H1 - Create the best documentation`}</h1>
    <h2>{`H2 - Create the best documentation`}</h2>
    <h3>{`H3 - Create the best documentation`}</h3>
    <h4>{`H4 - Create the best documentation`}</h4>
    <h5>{`H5 - Create the best documentation`}</h5>
    <h6>{`H6 - Create the best documentation`}</h6>
    <hr></hr>
    <h2>{`Emphasis`}</h2>
    <p>{`Emphasis, aka italics, with `}<em parentName="p">{`asterisks`}</em>{` or `}<em parentName="p">{`underscores`}</em>{`.`}</p>
    <p>{`Strong emphasis, aka bold, with `}<strong parentName="p">{`asterisks`}</strong>{` or `}<strong parentName="p">{`underscores`}</strong>{`.`}</p>
    <p>{`Combined emphasis with `}<strong parentName="p">{`asterisks and `}<em parentName="strong">{`underscores`}</em></strong>{`.`}</p>
    <p>{`Strikethrough uses two tildes. `}<del parentName="p">{`Scratch this.`}</del></p>
    <hr></hr>
    <h2>{`Lists`}</h2>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Another item`}
        <ul parentName="li">
          <li parentName="ul">{`Unordered sub-list.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Actual numbers don't matter, just that it's a number`}
        <ol parentName="li">
          <li parentName="ol">{`Ordered sub-list`}</li>
        </ol>
      </li>
      <li parentName="ol">{`And another item.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Or minuses`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Or pluses`}</li>
    </ul>
    <hr></hr>
    <h2>{`Links`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.google.com/"
      }}>{`I'm an inline-style link`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.google.com/",
        "title": "Google's Homepage"
      }}>{`I'm an inline-style link with title`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.mozilla.org/"
      }}>{`I'm a reference-style link`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://slashdot.org/"
      }}>{`You can use numbers for reference-style link definitions`}</a></p>
    <p>{`Or leave it empty and use the `}<a parentName="p" {...{
        "href": "http://www.reddit.com/"
      }}>{`link text itself`}</a>{`.`}</p>
    <p>{`URLs and URLs in angle brackets will automatically get turned into links. `}<a parentName="p" {...{
        "href": "http://www.example.com/"
      }}>{`http://www.example.com/`}</a>{` or `}<a parentName="p" {...{
        "href": "http://www.example.com/"
      }}>{`http://www.example.com/`}</a>{` and sometimes example.com (but not on GitHub, for example).`}</p>
    <p>{`Some text to show that the reference links can follow later.`}</p>
    <hr></hr>
    <h2>{`Images`}</h2>
    <p>{`Here's our logo (hover to see the title text):`}</p>
    <p>{`Inline-style: `}<img parentName="p" {...{
        "src": "https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png",
        "alt": "alt text",
        "title": "Logo Title Text 1"
      }}></img></p>
    <p>{`Reference-style: `}<img parentName="p" {...{
        "src": "https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png",
        "alt": "alt text",
        "title": "Logo Title Text 2"
      }}></img></p>
    <p>{`Images from any folder can be used by providing path to file. Path should be relative to markdown file.`}</p>
    <p><img parentName="p" {...{
        "src": "../../static/img/logo.svg",
        "alt": "img"
      }}></img></p>
    <hr></hr>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var s = 'JavaScript syntax highlighting';
alert(s);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`s = "Python syntax highlighting"
print(s)
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`No language indicated, so no syntax highlighting.
But let's throw in a <b>tag</b>.
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "{2}",
        "{2}": true
      }}>{`function highlightMe() {
  console.log('This line can be highlighted!');
}
`}</code></pre>
    <hr></hr>
    <h2>{`Tables`}</h2>
    <p>{`Colons can be used to align columns.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`There must be at least 3 dashes separating each header cell. The outer pipes (|) are optional, and you don't need to make the raw Markdown line up prettily. You can also use inline Markdown.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Markdown`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Less`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pretty`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Still`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`renders`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`nicely`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2>{`Blockquotes`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Blockquotes are very handy in email to emulate reply text. This line is part of the same quote.`}</p>
    </blockquote>
    <p>{`Quote break.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This is a very long line that will still be quoted properly when it wraps. Oh boy let's keep writing to make sure this is long enough to actually wrap for everyone. Oh, you can `}<em parentName="p">{`put`}</em>{` `}<strong parentName="p">{`Markdown`}</strong>{` into a blockquote.`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`Inline HTML`}</h2>
    <dl>
  <dt>Definition list</dt>
  <dd>Is something people use sometimes.</dd>
  <dt>Markdown in HTML</dt>
  <dd>Does *not* work **very** well. Use HTML <em>tags</em>.</dd>
    </dl>
    <hr></hr>
    <h2>{`Line Breaks`}</h2>
    <p>{`Here's a line for us to start with.`}</p>
    <p>{`This line is separated from the one above by two newlines, so it will be a `}<em parentName="p">{`separate paragraph`}</em>{`.`}</p>
    <p>{`This line is also a separate paragraph, but... This line is only separated by a single newline, so it's a separate line in the `}<em parentName="p">{`same paragraph`}</em>{`.`}</p>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      